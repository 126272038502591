<template>
  <v-app>
    <v-container fluid>
      <v-card
        class="mx-auto transparent"
        max-width="595"
        min-width="210"
        elevation="0"
      >
        <v-row class="mt-12">
          <v-col class="mt-8">
            <v-img
              class="ml-4"
              height="65"
              width="76"
              :src="logo"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row align="center" class="my-2 transparent">
          <v-col cols="9">
            <div class="text-start">
              <v-card elevation="0" class="transparent">
                <v-card-subtitle class="pt-0">
                  <p class="text-h6">
                    {{ 400 }}. {{ $ml.get("this_is_an_error") }}
                  </p>
                  <p class="mb-0">
                    {{ $ml.get("invalid_request") }}
                  </p>
                  {{ $ml.get("that_is_all_we_know") }}
                </v-card-subtitle>
              </v-card>
            </div>
          </v-col>
          <v-col cols="3">
            <v-icon size="65">mdi-laptop-off</v-icon>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "PageError",
  data() {
    return {
      logo: require("@/assets/appslogos/icons/icon-control.png"),
    };
  },
};
</script>
